import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import Navbar from "./Navbar";
import { Card } from "react-bootstrap";
import Vimeo from '@u-wave/react-vimeo';


function LivingStatues() {

  return (
    <>
    <Navbar />
    <Container fluid className="project-section">
      <Vimeo
        video={"942187730"}
        autoplay
        loop 
      />
      <p id="project-section-wip"></p>
      <p><span className="blur">NAMEREDACTED</span> is a series of digital interactive living statues that will begin to pop up in and around downtown Atlanta. <br></br>
      The project is a collaboration with the community and a temporal mirror of its inhabitants.<br></br> 
      To add yourself to the work, take a picture of yourself, something meaningful to you, or something that you feel represents the downtown community, and submit the picture using the link below.</p> 
      <p><a href="https://www.dropbox.com/request/PmgdZdYWS450skiqB7UW">Upload Photo Here</a></p>

      <p><a href="https://www.instagram.com/modeaxe">Contact The Artist</a></p><br></br>
      <p>Courtesy of: <a href="https://publicartfutureslab.com/">Public Art Futures Lab</a> </p>
    </Container>
    <Container fluid>
  </Container>    
    </>

  );
}

export default LivingStatues;
